export const HARD_WALL_STYLES = `
  :host {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    height: 100%;
    container: wistiaHardWall / size;
  }

  @container wistiaHardWall (height < 800px) {
    .form-container {
      scale: 0.9;
    }
  }

  .hard-wall-container {
    background: linear-gradient(var(--wistia-hard-wall-background-gradient-stop-one), var(--wistia-hard-wall-background-gradient-stop-two));
    width: 100%;
    height: 100%;
    padding: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    gap: 2rem;
    color: var(--wistia-hard-wall-form-text-color);
  }

  .logo-container {
    flex: 0 1 auto;
    overflow: hidden;
  }

  .logo-container img {
    max-height: 100%;
    max-width: 300px;
  }

  .form-container {
    flex: 1 0 auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }


  form {
    background: var(--wistia-hard-wall-form-background-color);
    border: 1px solid var(--wistia-hard-wall-form-border-color);
    padding: 24px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
  }

  input {
    width: 100%;
    margin: 1rem 0;
    height: 36px;
    border-radius: 9px;
    background-color: var(--wistia-hard-wall-form-input-background-color);
    color: var(--wistia-hard-wall-form-input-text-color);
    border-color: var(--wistia-hard-wall-form-input-border-color);
  }

  .input-error-message {
    color: var(--wistia-hard-wall-form-error-text-color);
    margin-bottom: 1em;
  }

  button[type="submit"] {
    background: var(--wistia-hard-wall-form-button-background-color);
    color: var(--wistia-hard-wall-form-button-text-color);
    border: 0;
    border-radius: 9px;
    padding: 0.75rem;
    cursor: pointer;

    &:hover:not([disabled]) {
      background: var(--wistia-hard-wall-form-button-hover-background-color);
      color: var(--wistia-hard-wall-form-button-hover-text-color);
    }
  }
`;
