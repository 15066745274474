import { ComponentChildren, Fragment, h, JSX, render } from 'preact';
import { useEffect, useRef } from 'preact/hooks';
import { isNil } from '@wistia/type-guards';
import { interFontFamily } from '../../../../utilities/interFontFamily.js';
import { Color } from '../../../../utilities/color.js';
import { dynamicImport } from '../../../../utilities/dynamicImport.ts';
import {
  generateHardWallColorPalette,
  getHardWallColorPaletteAsCssVars,
} from './hardWallColorPalette.ts';
import { HARD_WALL_STYLES } from './HardWallStyles.tsx';

type HardWallProps = {
  backgroundColor: string;
  children?: ComponentChildren;
  playerColor: string;
};

export const HardWall = ({
  backgroundColor,
  children,
  playerColor,
}: HardWallProps): JSX.Element => {
  const containerRef = useRef<HTMLDivElement>(null);
  const colorPalette = generateHardWallColorPalette(
    new Color(backgroundColor),
    new Color(playerColor),
  );

  useEffect(() => {
    void dynamicImport('assets/external/interFontFace.js');
    containerRef.current?.attachShadow({ mode: 'open' });
    // We just attached the shadow DOM, so it's not null.
    // But typescript thinks it might be, so we'll include this null-check.
    if (isNil(containerRef.current?.shadowRoot)) {
      return;
    }
    render(
      <Fragment>
        <style>
          {`
          :host {
            * {
              box-sizing: border-box;
              font-family: ${interFontFamily}
            }
            ${getHardWallColorPaletteAsCssVars(colorPalette)};
          }
          ${HARD_WALL_STYLES}
          `}
        </style>
        <div className="hard-wall-container" data-testid="hard-wall-container">
          {children}
        </div>
      </Fragment>,
      containerRef.current.shadowRoot,
    );
  }, []);

  return (
    <Fragment>
      <div ref={containerRef}></div>
    </Fragment>
  );
};
