import { h, JSX } from 'preact';
import { useRef, useState } from 'preact/hooks';
import { isNonEmptyString } from '@wistia/type-guards';
import { InvalidPasswordError } from './InvalidPasswordError.ts';

export const PasswordForm = ({
  channelTitle,
  onSubmitPassword,
}: {
  channelTitle: string;
  onSubmitPassword: (password?: string) => Promise<null>;
}): JSX.Element => {
  const passwordInputRef = useRef<HTMLInputElement>(null);
  const [shouldShowError, setShouldShowError] = useState(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);

  return (
    <div
      className="form-container"
      style={{
        flex: '1 0 auto',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
      }}
    >
      <form
        onSubmit={(event) => {
          event.preventDefault();
          event.stopPropagation();
          // Don't allow the form to be submitted again until we've handled the response
          // from any previous submission
          if (isSubmitDisabled) {
            return;
          }
          setIsSubmitDisabled(true);
          // TODO - filter out characters that are not allowed to be used in passwords
          onSubmitPassword(passwordInputRef.current?.value)
            .then(() => {
              // We did it!
              // Should we do some tracking here?
            })
            .catch((reason: unknown) => {
              if (reason instanceof InvalidPasswordError) {
                setShouldShowError(true);
              }
            })
            .finally(() => {
              setIsSubmitDisabled(false);
            });
        }}
      >
        <h1>Please enter your password</h1>
        <label>
          Password{' '}
          <input
            data-testid="password-input"
            type="password"
            onInput={() => {
              setShouldShowError(false);
              setIsSubmitDisabled(!isNonEmptyString(passwordInputRef.current?.value));
            }}
            ref={passwordInputRef}
            aria-label={`Password for ${channelTitle}`}
          />
        </label>
        {shouldShowError && (
          <span className="input-error-message" data-testid="error-text">
            WRONG PASSWORD!
          </span>
        )}
        <button data-testid="submit-button" type="submit" disabled={isSubmitDisabled}>
          Submit
        </button>
      </form>
    </div>
  );
};
