import { isNonEmptyString, Nilable } from '@wistia/type-guards';
import { h, JSX } from 'preact';
import { HardWall } from './HardWall.tsx';
import { PasswordForm } from './PasswordForm.tsx';

type HardWallWithPasswordFormProps = {
  backgroundColor: string;
  channelTitle: string;
  logoUrl?: Nilable<string>;
  onSubmitPassword: (password?: string) => Promise<null>;
  playerColor: string;
};

export const HardWallWithPasswordForm = ({
  backgroundColor,
  channelTitle,
  logoUrl,
  onSubmitPassword,
  playerColor,
}: HardWallWithPasswordFormProps): JSX.Element => {
  return (
    <HardWall backgroundColor={backgroundColor} playerColor={playerColor}>
      {isNonEmptyString(logoUrl) && (
        <div className="logo-container">
          <img src={logoUrl} alt="logo" />
        </div>
      )}
      <PasswordForm onSubmitPassword={onSubmitPassword} channelTitle={channelTitle} />
    </HardWall>
  );
};
