/* eslint-disable @typescript-eslint/no-magic-numbers */
import { camelCaseToKebabCase } from '../../../../utilities/camelCaseToKebabCase.ts';
import {
  adjustColorForProperContrast,
  getBestColorForShape,
} from '../../../../utilities/color-utils.ts';
import { Color } from '../../../../utilities/color.js';

export type HardWallColorPalette = {
  backgroundColor: string;
  backgroundGradientStopOne: string;
  backgroundGradientStopTwo: string;
  formBackgroundColor: string;
  formBorderColor: string;
  formButtonBackgroundColor: string;
  formButtonHoverBackgroundColor: string;
  formButtonHoverTextColor: string;
  formButtonTextColor: string;
  formErrorTextColor: string;
  formInputBackgroundColor: string;
  formInputBorderColor: string;
  formInputTextColor: string;
  formTextColor: string;
};

export const generateHardWallColorPalette = (
  backgroundColor: Color,
  playerColor: Color,
): HardWallColorPalette => {
  const colorPalette = [backgroundColor, playerColor];
  const formButtonBackgroundColor = playerColor.toHexWithHash();
  const formButtonTextColor = getBestColorForShape(colorPalette, playerColor);
  const formButtonHoverBackgroundColor = playerColor.isDark(true)
    ? new Color(playerColor).lighten(20)
    : new Color(playerColor).darken(20);
  const formButtonHoverTextColor = adjustColorForProperContrast(
    formButtonTextColor,
    formButtonHoverBackgroundColor,
  );

  if (backgroundColor.isDark(true)) {
    const formBackgroundColor = new Color(backgroundColor).tint(0.005, true);
    const formInputBackgroundColor = new Color(backgroundColor).tint(0.1, true);
    return {
      backgroundColor: backgroundColor.toHexWithHash(),
      backgroundGradientStopOne: backgroundColor.toHexWithHash(),
      backgroundGradientStopTwo: playerColor.isDark(true)
        ? new Color(playerColor).shade(0.6, true).toHexWithHash()
        : new Color(playerColor).shade(0.9, true).toHexWithHash(),
      formBackgroundColor: formBackgroundColor.toHexWithHash(),
      formBorderColor: new Color(backgroundColor).tint(0.07, true).toHexWithHash(),
      formButtonBackgroundColor,
      formButtonTextColor,
      formButtonHoverBackgroundColor: formButtonHoverBackgroundColor.toHexWithHash(),
      formButtonHoverTextColor,
      formInputBackgroundColor: formInputBackgroundColor.toHexWithHash(),
      formInputTextColor: getBestColorForShape(colorPalette, formInputBackgroundColor, [
        playerColor,
      ]),
      formInputBorderColor: new Color(backgroundColor).tint(0.05, true).toHexWithHash(),
      formTextColor: getBestColorForShape(colorPalette, formBackgroundColor, [playerColor]),
      formErrorTextColor: adjustColorForProperContrast('#FF6962', backgroundColor),
    };
  }

  const formBackgroundColor = new Color(backgroundColor);
  const formInputBackgroundColor = new Color(backgroundColor).shade(0.1, true);
  return {
    backgroundColor: backgroundColor.toHexWithHash(),
    backgroundGradientStopOne: backgroundColor.toHexWithHash(),
    backgroundGradientStopTwo: playerColor.isDark(true)
      ? new Color(playerColor).tint(0.6, true).toHexWithHash()
      : new Color(playerColor).tint(0.4, true).toHexWithHash(),
    formBackgroundColor: formBackgroundColor.toHexWithHash(),
    formBorderColor: new Color(backgroundColor).shade(0.07, true).toHexWithHash(),
    formButtonBackgroundColor,
    formButtonTextColor,
    formButtonHoverBackgroundColor: formButtonHoverBackgroundColor.toHexWithHash(),
    formButtonHoverTextColor,
    formInputBackgroundColor: formInputBackgroundColor.toHexWithHash(),
    formInputTextColor: getBestColorForShape(colorPalette, formInputBackgroundColor, [playerColor]),
    formInputBorderColor: new Color(backgroundColor).shade(0.05, true).toHexWithHash(),
    formTextColor: getBestColorForShape(colorPalette, formBackgroundColor, [playerColor]),
    formErrorTextColor: adjustColorForProperContrast('#860011', backgroundColor),
  };
};

export const getHardWallColorPaletteAsCssVars = (colorPalette: HardWallColorPalette): string => {
  return Object.entries(colorPalette)
    .map(([key, value]) => {
      const variableName = `--wistia-hard-wall-${camelCaseToKebabCase(key)}`;
      return `${variableName}:${value}`;
    })
    .join(';\n');
};
